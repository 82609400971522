import './App.css'
import React from 'react';
import {WorkItem} from "./App";
import {createUseStyles} from "react-jss";
import classNames from "classnames";
import appStoreBadge from './assets/img/apple-icon.svg'
import playStoreBadge from './assets/img/google-icon.png'
import githubBadge from './assets/img/github-icon.png'
import webBadge from './assets/img/web-icon.png'
import {Badge, Col, Row} from "reactstrap";

type WorkCardProps = {
    workItem: WorkItem,
    className?: string
}

const useStyles = createUseStyles({
    card: {
        padding: '1.25em',
        borderRadius: '16px'
    },
    icon: {
        right: 25,
        top: -30,
        marginTop: -40,
        width: '75px',
        height: '75px'
    },
    badge: {
        height: '2em',
        opacity: 0.25
    },
    imageContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    imagePortait: {
        maxHeight: 400,
        width: "auto",
        maxWidth: 292
    },
    imageLandscape: {
        height: 150,
        width: 'auto',
    }
})

const WorkCard: React.ComponentType<WorkCardProps> = (props) => {
    const {
        workItem,
        className
    } = props;

    const classes = useStyles();

    return (
        <div className={classNames(className, classes.card, 'mt-md-5')}
             style={{backgroundColor: workItem.backgroundColor ?? undefined, position: 'relative'}}>
            <div className={'d-flex justify-content-center'}>
                {workItem.icon && <img
                    alt={workItem.name}
                    src={require(`./assets/img/icons/${workItem.icon}`)}
                    className={classNames(classes.icon, 'position-md-absolute', 'mb-3 mt-md-0')}/>}
            </div>

            <h5 className={'font-weight-bold mb-2'} style={{fontSize: '1.3em'}}>
                {workItem.name}
            </h5>
            {!!workItem.subtitle && <h6 className={'text-muted mb-3'}>{workItem.subtitle}</h6>}
            <div dangerouslySetInnerHTML={{__html: workItem.description}}/>
            {workItem.images
            && workItem.images.length > 0
            && <div className={classes.imageContainer}>
                {workItem.images.map((image, i) => (
                    <div className={'p-1 bg-white rounded mb-2 shadow-sm'} key={i}>
                        <img alt={image}
                            src={process.env.PUBLIC_URL + image}
                            className={workItem.imageType === 'portrait' ? classes.imagePortait : classes.imageLandscape}/>
                    </div>
                ))}
            </div>}

            <Row className={'align-items-center'}>
                {!!workItem.tags && workItem.tags.length > 0 &&
                <Col className={'d-flex align-items-center mt-2 flex-grow-1 flex-wrap'}>
                    {workItem.tags.map(t => <Badge key={t} pill className={'mr-2 mt-2'}>{t}</Badge>)}
                </Col>}
                {workItem.links && Object.values(workItem.links).some(v => !!v) &&
                <Col className={'flex-grow-0 text-nowrap mt-3 d-flex align-items-center'}>
                    {workItem.links.appStore &&
                    <a target={'_blank'} rel="noopener noreferrer" href={workItem.links.appStore} className={'pr-2'}>
                        <img alt='App Store' src={appStoreBadge} className={classNames('py-1', classes.badge)}/>
                    </a>}
                    {workItem.links.googlePlay &&
                    <a target={'_blank'} href={workItem.links.googlePlay} rel="noopener noreferrer" className={'pr-2'}>
                        <img alt='Google Play Store' src={playStoreBadge} className={classes.badge}/>
                    </a>}
                    {workItem.links.gitHub &&
                    <a target={'_blank'} rel="noopener noreferrer" href={workItem.links.gitHub}>
                        <img alt={'GitHub'} src={githubBadge} className={classNames(classes.badge, 'py-1')}/>
                    </a>}
                    {workItem.links.web && <a target={'_blank'} rel="noopener noreferrer" href={workItem.links.web}>
                        <img alt={'GitHub'} src={webBadge} className={classNames(classes.badge, 'py-1')}/>
                    </a>}
                </Col>}
            </Row>

        </div>
    );
}

export default WorkCard