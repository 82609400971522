import './App.css'

import React from 'react';
import headshot from './assets/img/headshot@2x.png';
import {Row, Col, Container, Button } from 'reactstrap'
import {createUseStyles} from 'react-jss'
import classNames from 'classnames';
import rawWork from './assets/work.json'
import WorkCard from "./WorkCard";

export type WorkItem = {
    name: string,
    subtitle?: string | null,
    description: string,
    backgroundColor: string | null | undefined,
    tags: string[] | null | undefined
    images: never[],
    imageType: 'portrait' | 'landscape' | null | undefined
    icon: string | null | undefined,
    links: {
        googlePlay?: string,
        appStore?: string,
        gitHub?: string,
        web?: string
    }
}

const work = rawWork as WorkItem[]

const useStyles = createUseStyles({
    headshot: {
        width: '12.5rem',
        height: '12.5rem'
    },
    name: {},
    bio: {
        fontSize: '1.2em'
    }
})

function App() {
    const classes = useStyles();
    console.log('hello', work)
    return (
        <Container className={'pt-5'}>
            <Row className={'justify-content-center mb-4'}>
                <Col className={'flex-grow-0'}>
                    <img alt="headshot" src={headshot} className={classes.headshot}/>
                </Col>
                <Col xs={12} lg={8} className={'pt-4'}>
                    <h2 className={classNames(classes.name, 'mb-3', 'test')}>
                        Matthew Tory
                    </h2>
                    <div className={classes.bio}>
                        Hello! I'm a software developer based in Toronto, Canada, currently working at Shopify.
                    </div>
                    <div className={'mt-3'}>
                        <Button color={'secondary'} outline href={'mailto:matthew.tory@gmail.com'}>Contact</Button>
                    </div>
                </Col>
            </Row>
            <div className={'px-lg-5'}>
                <h4 className={'mb-4'}>Work</h4>
                {work.map((w, i) => (
                    <div className={'mb-5'} key={i}>
                        <WorkCard workItem={w} />
                        <div className={'d-sm-none d-md-block'}>&nbsp;</div>
                    </div>
                ))}
            </div>

        </Container>
    );
}

export default App;
